import albingia_transparent from '../images/albingia_transparent.png';
import { useNavigate } from 'react-router-dom';
import { Fade } from '@mui/material';
import { useSelector } from 'react-redux';

export function Splash() {

    const navigate = useNavigate();

    const credentials = useSelector(state => state.credentials);
    if (!credentials) {
        setTimeout(() => {
            console.log('err - no credentials');
            navigate('/login');
        }, 1000);
    } else {
        setTimeout(() => {
            navigate('/main');
        }, 1000);
    };

    return (
        <Fade in={true}>
            <div
                style={{
                    background: 'linear-gradient(32deg, rgba(203,27,27,1) 0%, rgba(225,5,113,1) 100%)',
                    height: '100vh'
                }}>
                <img
                    src={albingia_transparent}
                    height='20%'
                    style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        paddingTop: '4%',
                        paddingBottom: '5%'
                    }}
                    alt=''
                />
            </div>
        </Fade>
    )
}