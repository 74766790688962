import {
    UPLOAD_FILES,
    LOADING,
    UPLOAD_PROGRESS,
    SET_CREDENTIALS,
    LOGOUT,
    ACCESS_DENIED,
    CHECK_DATA_CONSISTENCY,
    DISMISS_ERROR_MESSAGE,
} from '../actions';
import { v4 as uuidv4 } from 'uuid';

function download(filename, text) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
};

const initialState = { accessDenied: false, loading: false };

function rootReducer(state = initialState, action) {
    state.loading = false;
    // state.accessDenied = false;
    if (state.errors) {
        delete state.errors;
    };
    switch (action.type) {
        case LOADING:
            state = Object.assign({}, state, { loading: action.loading, loadingMessage: action.loadingMessage });
            return state;
        case UPLOAD_FILES:
            if (action.errorCSV) {
                download(uuidv4() + ".csv", action.errorCSV);
            }
            state = Object.assign({}, state, { finished: action.finished, errors: action.errors });
            return state;
        case CHECK_DATA_CONSISTENCY:
            if (action.errorCSV) {
                download(uuidv4() + ".csv", action.errorCSV);
            }
            state = Object.assign({}, state, { finished: action.finished, errors: action.errors, errorMessage: action.errorMessage });
            return state;
        case DISMISS_ERROR_MESSAGE:
            state = Object.assign({}, state, { errors: false, errorMessage: null, errorMessageDismissed: true });
            return state;
        case UPLOAD_PROGRESS:
            state = Object.assign({}, state, { progress: action.progress, total: action.total, loading: true });
            return state;
        case SET_CREDENTIALS:
            state = Object.assign({}, state, { credentials: action.data, userId: action?.userId });
            return state;
        case ACCESS_DENIED:
            state = Object.assign({}, state, { accessDenied: true });
            return state;
        case LOGOUT:
            console.log('logging out, deleting state.credentials');
            delete state.credentials;
            window.location.replace(process.env.REACT_APP_REDIRECT + '/login');
            break;
        default:
            return state;
    }
}

export default rootReducer