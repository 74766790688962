import albingia_transparent from '../../images/albingia_transparent.png';
import { Fade } from '@mui/material';
import { CognitoIdentityClient, GetIdCommand, GetCredentialsForIdentityCommand } from "@aws-sdk/client-cognito-identity";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials } from '../../app/actions';
import { useNavigate } from 'react-router-dom';

const cognito = new CognitoIdentityClient({
    region: process.env.REACT_APP_REGION
});

function getSamlResponse() {
    return decodeURI(new URLSearchParams(window.location.search).get("SamlResponse"));
};

export function Redirect() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const credentials = useSelector(state => state.credentials);

    useEffect(() => {
        const idpArn = process.env.REACT_APP_IDP_ARN;
        async function callIdentityPool() {
            const getIdParams = {
                AccountId: process.env.REACT_APP_ACCOUNT_ID,
                Logins: {},
                IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
            };
            getIdParams.Logins[idpArn] = getSamlResponse();
            const getIdCommand = new GetIdCommand(getIdParams);
            let data = await cognito.send(getIdCommand);

            const getCredentialsForIdentityParams = {
                IdentityId: data.IdentityId,
                Logins: {},
                CustomRoleArn: process.env.REACT_APP_CUSTOM_ROLE_ARN
            };
            getCredentialsForIdentityParams.Logins[idpArn] = getSamlResponse();
            const getCredentialsForIdentityCommand = new GetCredentialsForIdentityCommand(getCredentialsForIdentityParams);
            let credResponse = await cognito.send(getCredentialsForIdentityCommand);
            dispatch(setCredentials(credResponse.Credentials));
        };
        callIdentityPool();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (credentials) {
            navigate('/main');
        };
    }, [credentials]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fade in={true}>
            <div
                style={{
                    background: 'linear-gradient(32deg, rgba(203,27,27,1) 0%, rgba(225,5,113,1) 100%)',
                    height: '100vh'
                }}>
                <img
                    src={albingia_transparent}
                    height='20%'
                    style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        paddingTop: '4%',
                        paddingBottom: '5%'
                    }}
                    alt=''
                />
            </div>
        </Fade>
    )
}