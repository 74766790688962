import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../app/actions";

export default function Login() {
    const dispatch = useDispatch();
    const credentials = useSelector(state => state.credentials);
    useEffect(() => {
        if (!credentials) {
            console.log('err - no credentials');
            window.location.replace(process.env.REACT_APP_REDIRECT + '/login');
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    async function login() {
        try {
            dispatch(logOut());
        } catch (e) {
            console.log(e);
        }
    }
    login();
};