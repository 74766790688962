import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './component/auth/login';
import { checkUser } from './component/auth/login';
import Logout from './component/auth/logout';
import { Main } from './component/main';
import { Splash } from './component/splash';
import { Redirect } from './component/auth/redirect';
import Footer from './widgets/footer';


function AppContent() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/login" element={<><Footer /><Login /></>} onEntry={Login} />
        <Route path="/logout" element={<><Footer /><Logout /></>} onEntry={checkUser} />
        <Route path="/" element={<><Footer /><Splash /></>} onEntry={checkUser} />
        <Route path="/main" element={<><Footer /><Main /></>} onEntry={checkUser} />
        <Route path="/redirect" element={<><Footer /><Redirect /></>} onEntry={checkUser} />
      </Routes>
    </React.Fragment>
  );
}

export default function App() {
  return <AppContent />;
}
