import {
    UPLOAD_FILES,
    LOADING,
    UPLOAD_PROGRESS,
    SET_CREDENTIALS,
    LOGOUT,
    ACCESS_DENIED,
    CHECK_DATA_CONSISTENCY
} from '../actions';
import { S3Client, ListObjectsV2Command } from "@aws-sdk/client-s3";
import Papa from 'papaparse';
import { STSClient, GetCallerIdentityCommand } from "@aws-sdk/client-sts";
import { InvokeCommand, LambdaClient } from "@aws-sdk/client-lambda";
import { Upload } from '@aws-sdk/lib-storage';

function hexToBase64(hexstring) {
    return btoa(hexstring.match(/\w{2}/g).map(function (a) {
        return String.fromCharCode(parseInt(a, 16));
    }).join(""));
};

function* toCsvRows(headers, columns) {
    yield headers

    const numRows = columns.map(col => col.length)
        .reduce((a, b) => Math.max(a, b))

    for (let row = 0; row < numRows; row++) {
        yield columns.map(c => c[row] || '')
    }
}

/**
 * Generates the string to create a CSV file
 * @param {Array<String>} data
 * @returns {String}
 */
function toCsvString(data) {
    let output = ''
    for (let row of data) {
        output += row.join(',') + '\n'
    }
    return output
}


/**
 * Generates a CSV string from headers and columns
 * @param {Array<String>} headers
 * @param {Array<String>} columns
 */
function csvConstructor(headers, columns) {
    return toCsvString(toCsvRows(headers, columns));
};

/**
 * Checks whether the filename has the required structure
 * @param {String} filename
 * @returns {Boolean}
 */
function isFilenameValid(filename) {
    if (!filename.includes('_')) {
        return false;
    }

    const pattern = /[a-zA-Z0-9]+_[a-zA-Z0-9]+_[a-zA-Z0-9_\s]+\.pdf/gi;

    return pattern.test(filename);
}

/**
 * Creates an S3 client
 * @param {Object} creds
 * @returns {S3Client}
 */
function createS3Client(creds) {
    console.log('setting s3 client')
    const s3 = new S3Client({
        region: process.env.REACT_APP_REGION,
        credentials: {
            accessKeyId: creds?.AccessKeyId,
            secretAccessKey: creds?.SecretKey,
            sessionToken: creds?.SessionToken,
            expiration: creds?.Expiration
        }
    });
    console.log('s3 client set');

    return s3;
}

/**
 * Parses a checksum CSV
 * @param {String} csvString
 * @returns
 */
async function parseCsv(csvString) {
    return new Promise((resolve, reject) => {
        Papa.parse(csvString, {
            header: false,
            skipEmptyLines: true,
            complete: (results) => {
                resolve(results);
            },
            error: (error, file) => {
                reject(error, file);
            }
        });
    })
}

/**
 * Checks whether objectPath exists in the S3 bucket
 * @param {S3Client} s3Client
 * @param {String} bucketName
 * @param {String} objectPath
 * @returns {Boolean}
 */
async function objectExists(s3Client, bucketName, objectPath) {
    const params = {
        Bucket: bucketName,
        Prefix: objectPath,
    };
    const listCommand = new ListObjectsV2Command(params);
    const objectList = await s3Client.send(listCommand);

    if (objectList?.Contents && objectList?.Contents?.length > 0) {
        for (let i in objectList?.Contents) {
            if (objectList?.Contents[i].Key === params.Prefix) {
                return true;
            }
        }
    }
    return false;
}

/**
 * Uploads file to the S3 bucket at the specified path
 * @param {S3Client} s3Client
 * @param {String} bucketName
 * @param {String} objectPath
 * @param {File} file
 * @returns
 */
const uploadFile = (s3Client, bucketName, objectPath, file) => {
    const params = {
        Bucket: bucketName,
        Key: objectPath,
        Body: file.file,
        ContentType: file.file.type,
        ObjectLockLegalHoldStatus: "ON",
        ChecksumAlgorithm: 'SHA256',
        ChecksumSHA256: hexToBase64(file.checksum),
        StorageClass: 'STANDARD_IA'
    };

    const upload = new Upload({
        client: s3Client,
        params: params,
        partSize: 1024 * 1024 * 1024 * 5,
        maxTotalParts: 1
    });

    return upload.done();
};

async function checkDataConsistency(dispatch, getState, action) {
    dispatch({ type: LOADING, loading: true, loadingMessage: 'Checking consistency...' });

    const { credentials } = getState()

    action.errors = false;

    const csv = action.data.csv;

    const { data: parsedCsv } = await parseCsv(csv);
    const parsedCsvNames = parsedCsv.map((row) => {
        return row[0];
    });

    const apiPayload = parsedCsvNames.map(fullName => {
        const splitName = fullName.split('_');
        return {
            idContrat: splitName[0],
            idAliment: splitName[1] || '0000'
        };
    });

    const lambda = new LambdaClient({
        credentials: {
            accessKeyId: credentials?.AccessKeyId,
            secretAccessKey: credentials?.SecretKey,
            sessionToken: credentials?.SessionToken,
            expiration: credentials?.Expiration
        },
        region: process.env.REACT_APP_REGION
    });

    const command = new InvokeCommand({
        FunctionName: process.env.REACT_APP_CONSISTENCY_CHECK_LAMBDA_ARN,
        Payload: JSON.stringify(apiPayload)
    });



    try {
        const response = await lambda.send(command)
        const jsonResponse = JSON.parse(new TextDecoder().decode(response.Payload))
        const headers = ['IDContrat', 'IDAliment']
        const invalidPairs = jsonResponse['Contrats'].filter((x) => !x.valide)
        if (invalidPairs && Array.isArray(invalidPairs) && invalidPairs.length) {
            const csvContents = [invalidPairs.map(pair => pair.idContrat), invalidPairs.map(pair => pair.idAliment) ]
            action.errorCSV = csvConstructor(headers, csvContents);
            action.errors = true;
            action.errorMessage = `There were ${invalidPairs.length} errors matching contracts and supply numbers, a summary file will be downloaded.`
        }
    } catch (e) {
        console.error('There was an error', e);
        if (e.name === 'ExpiredTokenException') {
            console.error('Credentials expired')
            dispatch({ type: ACCESS_DENIED })
        }
    }
}

/**
 * Checks whether files are valid, uploads them and consolidates errors if any
 * @param {import('react').Dispatch} dispatch
 * @param {*} action
 */
async function uploadFiles(dispatch, getState, action) {
    dispatch({ type: LOADING, loading: true });
    action.errors = false;

    const { credentials } = getState()

    const s3 = createS3Client(credentials);

    const filesToUpload = [];
    const filteredPDFs = [...action.data.pdfs].filter((file) => file.type === 'application/pdf');
    const pdfs = Object.values(filteredPDFs);
    const csv = action.data.csv;

    const { data: parsedCsv } = await parseCsv(csv);

    let parsedCsvNames = parsedCsv.map((row) => {
        return row[0];
    });

    // Build a map to dynamically retrieve checksums
    let csvNameToChecksumMap = new Map(parsedCsv.map((csvRow) => [csvRow[0], csvRow[1]]));

    let orphanedPDFs = [];
    for (let i in pdfs) {
        const currentPdfChecksum = csvNameToChecksumMap.get(pdfs[i].name);
        if (currentPdfChecksum != null) {
            filesToUpload.push({
                file: pdfs[i], checksum: currentPdfChecksum
            });
        } else {
            orphanedPDFs.push(pdfs[i].name);
        };
    };

    dispatch({ type: UPLOAD_PROGRESS, progress: 0, total: filesToUpload?.length });
    if (orphanedPDFs?.length > 0) {
        action.errors = true;
    };

    let orphanedChecksums = [];
    let pdfNames = pdfs.map((file) => {
        return file.name;
    });
    for (let i in parsedCsvNames) {
        if (!pdfNames?.includes(parsedCsvNames[i])) {
            orphanedChecksums.push(parsedCsvNames[i]);
        };
    };
    if (orphanedChecksums?.length > 0) {
        action.errors = true;
    };

    let failedToUpload = [];
    let badName = [];
    let alreadyExists = [];
    let untreated = [];
    for (let i in filesToUpload) {
        const file = filesToUpload[i];
        // Verifying name is valid
        if (!isFilenameValid(file.file.name)) {
            badName.push(file.file.name);
        } else {
            const [namePrefix] = file.file.name.split('_');
            const objPath = `archives/${namePrefix}/${file.file.name}`;
            // Verifying object doesn't already exist
            try {
                const foundObj = await objectExists(s3, process.env.REACT_APP_BUCKET_NAME, objPath);
                if (foundObj) {
                    alreadyExists.push(file.file.name);
                } else {
                    try {
                        console.log('uploading', filesToUpload[i].file.name);
                        await uploadFile(s3, process.env.REACT_APP_BUCKET_NAME, objPath, filesToUpload[i]);
                        console.log('uploaded', filesToUpload[i].file.name);
                    } catch (err) {
                        console.log('err', err)
                        failedToUpload.push(filesToUpload[i].file.name);
                        action.errors = true;
                    };
                    dispatch({ type: UPLOAD_PROGRESS, progress: i * 1 + 1, total: filesToUpload?.length });
                }
            } catch (err) {
                // Expired tokens can also return a 400
                // ... I think?
                console.log('err', err)
                if (err?.$metadata?.httpStatusCode === 403 || err?.$metadata?.httpStatusCode === 400) {
                    let untreatedArray = filesToUpload.slice(i);
                    console.log('untreatedArray', untreatedArray);

                    for (let j in untreatedArray) {
                        let untreatedFile = untreatedArray[j];
                        console.log('untreatedFile', untreatedFile);
                        untreated.push(untreatedFile.file.name);
                    };
                    dispatch({ type: ACCESS_DENIED });
                };
            }
        }
    };
    action.finished = true;

    if (failedToUpload.length > 0
        || orphanedChecksums.length > 0
        || orphanedPDFs.length > 0
        || badName.length > 0
        || alreadyExists.length > 0
        || untreated.length > 0
    ) {
        const headers = [
            "File failed to upload",
            "PDF has no associated CSV entry",
            "CSV entry has no associated PDF",
            "PDF file has invalid name format (must contain _ )",
            "File already exists",
            "File was not treated"
        ];
        const columns = [failedToUpload, orphanedPDFs, orphanedChecksums, badName, alreadyExists, untreated];
        const errorCSV = csvConstructor(headers, columns);

        action.errorCSV = errorCSV;
    };
}

export function middleware({ getState, dispatch }) {
    return function (next) {
        return async function (action) {
            if (action.type === UPLOAD_FILES) {
                await uploadFiles(dispatch, getState, action);
            } else if (action.type === CHECK_DATA_CONSISTENCY) {
                await checkDataConsistency(dispatch, getState, action);
            } else if (action.type === UPLOAD_PROGRESS) {
                dispatch({ type: LOADING, loading: true });
            } else if (action.type === ACCESS_DENIED) {
                dispatch({ type: LOADING, loading: false });
            } else if (action.type === SET_CREDENTIALS) {
                dispatch({ type: LOADING, loading: true });
                try {
                    const stsClient = new STSClient({
                        credentials: {
                            accessKeyId: action.data.AccessKeyId,
                            secretAccessKey: action.data.SecretKey,
                            sessionToken: action.data.SessionToken,
                            expiration: action.data.Expiration
                        },
                        region: process.env.REACT_APP_REGION
                    });
                    const callerIdCommand = new GetCallerIdentityCommand()
                    const response = await stsClient.send(callerIdCommand);
                    action.userId = response?.UserId?.split(':')[1];
                } catch (err) {
                    console.log('err')
                }

            } else if (action.type === LOGOUT) {
            }
            return next(action);
        };
    };
};
