import { Box, CircularProgress, Fade, Grid, Modal, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFiles, checkDataConsistency, dismissErrorMessage } from '../app/actions';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import albingia_transparent from '../images/albingia_transparent.png';
import { useNavigate } from 'react-router-dom';
import './main.css';

export function Main() {
    const credentials = useSelector(state => state.credentials);
    const userId = useSelector(state => state.userId);
    const accessDenied = useSelector(state => state.accessDenied);
    useEffect(() => {
        if (!credentials) {
            console.log('err - no credentials');
            window.location.replace(process.env.REACT_APP_REDIRECT + '/login');
        };
    }, [credentials]); // eslint-disable-line react-hooks/exhaustive-deps

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleAddPDFs = (files) => {
        if (files?.length > 0) {
            setPdfs(files);
        };
    };
    const handleAddCSVs = (e) => {
        let file = e.target.files[0];
        setCsv(file);
        dispatch(checkDataConsistency(file))
    };
    const handleUploadToS3 = () => {
        setLocalFinished(false);
        dispatch(uploadFiles(pdfs, csv, credentials));
        setPdfs(null);
        setCsv(null);
    };

    const [pdfs, setPdfs] = useState(null);
    const [csv, setCsv] = useState(null);

    const loading = useSelector(state => state.loading);
    const finished = useSelector(state => state.finished);
    const errors = useSelector(state => state.errors);
    const progress = useSelector(state => state.progress);
    const total = useSelector(state => state.total);
    const loadingMessage = useSelector(state => state.loadingMessage);
    const errorMessage = useSelector(state => state.errorMessage);
    const errorMessageDismissed = useSelector(state => state.errorMessageDismissed);

    const [localFinished, setLocalFinished] = useState(null);

    useEffect(() => {
        if (finished) {
            setLocalFinished(true);
        } else {
            setLocalFinished(false);
        }
    }, [finished]);

    const handleLogout = () => {
        navigate('/logout');
    };

    const tooltips = {
        pdf: 'Select a folder containing all the PDFs you want to upload',
        csv: 'Select a CSV file with all the names and corresponding checksums of your PDFs',
        upload: 'Upload your files'
    };

    return (
        <div
            style={{
                background: 'linear-gradient(32deg, rgba(203,27,27,1) 0%, rgba(225,5,113,1) 100%)',
                height: '100vh'
            }}
        >
            <Modal
                open={accessDenied}
                style={{
                    textAlign: "center",
                    paddingTop: "45vh",
                    position: 'absolute',
                    paddingLeft: "33%"
                }}
            >
                <Box sx={{ alignItems: 'center', width: '50%', left: '20px' }}>
                    <Box sx={{ minWidth: 35 }}>
                        <Typography variant="body2" color="white" >Access Denied</Typography>
                        <Typography variant="body2" color="white" >Your progress has been saved. Please reload the page and try again, previously treated files will be ignored.</Typography>
                    </Box>
                    <Box style={{ marginTop: '7%' }}>
                        <Button
                            onClick={() => window.location.reload()}
                            style={{ color: 'white' }}
                        >Reload</Button>
                    </Box>
                </Box>

            </Modal>
            <Button
                component='label'
                onClick={handleLogout}
                style={{
                    color: 'white',
                    position: 'absolute',
                    top: '2%',
                    right: '2%'
                }}
            >
                Log out
            </Button>
            <Modal
                open={loading}
                style={{
                    textAlign: "center",
                    paddingTop: "45vh",
                    position: 'absolute',
                    paddingLeft: "33%"
                }}
            >
                <Box sx={{ alignItems: 'center', width: '50%', left: '20px' }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <CircularProgress style={{ color: 'white' }} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                        { loadingMessage ? (
                            <Typography variant="body2" color="white" >Checking files consistency</Typography>
                        ) : (
                            <Typography variant="body2" color="white" >{"Treated " + progress + " files out of " + total}</Typography>
                        )}
                    </Box>
                </Box>
            </Modal>
            <Fade in={true}>
                <img
                    src={albingia_transparent}
                    height='20%'
                    style={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        paddingTop: '4%',
                        paddingBottom: '5%'
                    }}
                    alt=''
                />
            </Fade>
            <Typography
                style={{
                    position: 'fixed',
                    top: '2%',
                    left: '2%',
                    color: 'white'
                }}
            >
                {userId ? `Welcome, ${userId}` : null}
            </Typography>
            <Fade in={true}>
                <Grid container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    display='flex'
                    justifyContent='center'
                    flexWrap='nowrap'
                >
                    <Tooltip title={tooltips.csv}>
                        <Grid item>
                            <Button
                                component='label'
                                style={{
                                    color: 'white'
                                }}
                            >
                                Select checksum file
                                <input
                                    type='file'
                                    hidden
                                    onChange={(e) => {
                                        handleAddCSVs(e);
                                        e.target.value = '';
                                    }}
                                    data-testid="csvInput"
                                />
                            </Button>
                        </Grid>
                    </Tooltip>

                    <Fade
                        in={csv && !errors ? true : false}
                        timeout={500}
                    >
                        <ArrowDownwardIcon
                            fontSize='large'
                            style={{
                                color: 'white'
                            }}
                        />
                    </Fade>

                    <Tooltip title={tooltips.pdf} display={csv && !errors ? 'block' : 'none'}>
                        <Grid item>
                            <Fade
                                in={csv && !errors  ? true : false}
                                timeout={1000}
                            >
                                <Button
                                    component='label'
                                    style={{
                                        color: 'white',
                                    }}
                                    disabled={csv && !errors ? false : true}
                                >
                                    Select PDFs folder {pdfs ? '(' + pdfs?.length + ' files selected)' : null}
                                    <input
                                        type='file'
                                        hidden
                                        webkitdirectory="true"
                                        onClick={(event) => {
                                            event.target.value = null;
                                        }}
                                        onChange={(e) => {
                                            if (e.target.files?.length > 0) {
                                                let files = [];
                                                for (let i in e.target.files) {
                                                    if (e.target.files[i].type === 'application/pdf') {
                                                        files.push(e.target.files[i]);
                                                    }
                                                }
                                                handleAddPDFs(files);
                                            } else {
                                                // e.target.value = '';
                                            }
                                        }}
                                        multiple
                                        data-testid="pdfInput"
                                    />
                                </Button>
                            </Fade>
                        </Grid>
                    </Tooltip>
                    <Fade
                        in={pdfs ? true : false}
                        timeout={500}
                    >
                        <ArrowDownwardIcon
                            fontSize='large'
                            style={{
                                color: 'white'
                            }}
                        />
                    </Fade>

                    <Tooltip title={tooltips.upload} display={pdfs ? 'block' : 'none'}>
                        <Grid item>
                            <Fade
                                in={pdfs ? true : false}
                                timeout={1000}
                            >
                                <Button
                                    component='label'
                                    onClick={handleUploadToS3}
                                    style={{
                                        color: 'white'
                                    }}
                                    disabled={pdfs ? false : true}
                                >
                                    Upload
                                </Button>
                            </Fade>
                        </Grid>
                    </Tooltip>
                    <Modal
                        open={localFinished && !accessDenied}
                        style={{
                            textAlign: "center",
                            paddingTop: "45vh",
                            position: 'absolute',
                            paddingLeft: "33%"
                        }}
                    >
                        <Box sx={{ alignItems: 'center', width: '50%', left: '20px' }}>
                            <Box sx={{ minWidth: 35 }}>
                                <Typography variant="body2" color="white" >{errors ? 'Files uploaded with errors' : 'Files uploaded'}</Typography>
                                <Button
                                    style={{ color: 'white', marginTop:"2%" }}
                                    onClick={()=>{setLocalFinished(false)}}
                                >Close</Button>
                            </Box>
                        </Box>
                    </Modal>
                    <Modal
                        open={errorMessage && !errorMessageDismissed}
                        style={{
                            textAlign: "center",
                            paddingTop: "45vh",
                            position: 'absolute',
                            paddingLeft: "33%"
                        }}
                    >
                        <Box sx={{ alignItems: 'center', width: '50%', left: '20px' }}>
                            <Box sx={{ minWidth: 35 }}>
                                <Typography variant="body2" color="white" >{errorMessage}</Typography>
                                <Button
                                    style={{ color: 'white', marginTop:"2%" }}
                                    onClick={()=>{dispatch(dismissErrorMessage())}}
                                >Close</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Grid>
            </Fade>
        </div>
    )
}