import { Button, Fade } from "@mui/material";
import albingia_transparent from '../../images/albingia_transparent.png';

export default function Login() {

    async function login() {
        try {
            window.location.replace(`https://account.activedirectory.windowsazure.com/applications/signin/${process.env.REACT_APP_AZURE_APP_ID}?tenantId=${process.env.REACT_APP_AZURE_TENANT_ID}`)
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div
            style={{
                background: 'linear-gradient(32deg, rgba(203,27,27,1) 0%, rgba(225,5,113,1) 100%)',
                height: '100vh'
            }}
        >
            <Fade in={true}>
                <div
                    style={{
                        background: 'linear-gradient(32deg, rgba(203,27,27,1) 0%, rgba(225,5,113,1) 100%)',
                        height: '100vh'
                    }}>
                    <img
                        src={albingia_transparent}
                        height='20%'
                        style={{
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block',
                            paddingTop: '4%',
                            paddingBottom: '5%'
                        }}
                        alt=''
                    />
                </div>
            </Fade>
            <Fade in={true}>
                <Button
                    onClick={() => login()}
                    style={{
                        color: 'white',
                        position: 'absolute',
                        top: '50%',
                        right: '48vw'
                    }}
                >
                    Login
                </Button>
            </Fade>
        </div>
    )
}

export function checkUser() {

};