export const LOADING = 'LOADING';
export const UPLOAD_FILES = 'UPLOAD_FILE';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const LOGOUT = 'LOGOUT';
export const ACCESS_DENIED = 'ACCESS_DENIED';
export const CHECK_DATA_CONSISTENCY = 'CHECK_DATA_CONSISTENCY';
export const DISMISS_ERROR_MESSAGE = 'DISMISS_ERROR_MESSAGE'

export function uploadFiles(pdfs, csv, credentials) {
    console.log("Action UPLOAD_FILES", pdfs, csv);
    return { type: UPLOAD_FILES, data: { pdfs, csv, credentials } };
};

export function loading(loading) {
    console.log("Action LOADING", loading);
    return { type: LOADING, data: loading };
};

export function uploadProgress(progress, total) {
    console.log("Action UPLOAD_PROGRESS", progress, total);
    return { type: UPLOAD_PROGRESS, data: { progress, total } }
};

export function setCredentials(credentials) {
    console.log('Action SET_CREDENTIALS');
    return { type: SET_CREDENTIALS, data: credentials };
};

export function logOut() {
    console.log('Action LOGOUT');
    return { type: LOGOUT };
};

export function accessDenied() {
    console.log('Action ACCESS_DENIED');
    return { type: ACCESS_DENIED };
}

export function checkDataConsistency(csv, credentials) {
    console.log('Action CHECK_DATA_CONSISTENCY');
    return { type: CHECK_DATA_CONSISTENCY, data: { csv, credentials } }
}

export function dismissErrorMessage() {
    console.log('Action DISMISS_ERROR_MESSAGE');
    return { type: DISMISS_ERROR_MESSAGE };
}